import store from '../store'
import AuthMiddleware from './middlewares/AuthMiddleware'
import GuestMiddleware from './middlewares/GuestMiddleware'
import AdminMiddleware from './middlewares/AdminMiddleware'
import EkaterinaMiddleware from './middlewares/EkaterinaMiddleware'
import LogoutHook from './hooks/LogoutHook'
import ManagerAndBiggerMiddleware from './middlewares/ManagerAndBiggerMiddleware'
import DoerAndBiggerMiddleware from './middlewares/DoerAndBiggerMiddleware'

const routes = [
  // Home Page
  {
    path: '/',
    name: 'home',
    meta: {
      middleware: [AuthMiddleware]
    },
    redirect() {
      return { name: 'time-log-weekly' }
    }
  },
  // Time log
  {
    path: '/time-log',
    name: 'time-log',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    redirect() {
      return { name: 'time-log-weekly' }
    }
  },
  {
    path: '/time-log/weekly',
    name: 'time-log-weekly',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "weekly-log" */ '../views/WeeklyLog.vue')
  },
  {
    path: '/time-log/team',
    name: 'time-log-team',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "team-time-log" */ '../views/TeamTimeLog.vue')
  },
  // Analytics
  {
    path: '/analytics-old',
    name: 'analytics-old',
    meta: {
      middleware: [AuthMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "analytics" */ '../views/Analytics.vue')
  },
  {
    path: '/analytics',
    name: 'analytics',
    meta: {
      middleware: [AuthMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "analytics" */ '../views/AnalyticsV2.vue')
  },
  // Settings
  {
    path: '/settings',
    name: 'settings',
    meta: {
      middleware: [AuthMiddleware, AdminMiddleware]
    },
    redirect() {
      return { name: 'settings-users-rates-activity-tags' }
    }
  },
  {
    path: '/settings/users-rates-activity-tags',
    name: 'settings-users-rates-activity-tags',
    meta: {
      middleware: [AuthMiddleware, AdminMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/settings/clients/overview',
    name: 'clients-settings-overview',
    meta: {
      middleware: [AuthMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "clients-settings" */ '../views/ClientsSettings.vue'
        )
  },
  {
    path: '/settings/projects/overview',
    name: 'projects-settings',
    meta: {
      middleware: [AuthMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "project-settings" */ '../views/ProjectsSettings.vue'
        )
  },
  {
    path: '/settings/clients/am',
    name: 'clients-settings-am',
    meta: {
      middleware: [AuthMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "clients-settings-am" */ '../views/ClientsSettingsAm.vue'
        )
  },
  {
    path: '/settings/clients/am-supervisor',
    name: 'clients-settings-am-supervisor',
    meta: {
      middleware: [AuthMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "clients-settings-am-supervisor" */ '../views/ClientsSettingsAmSupervisor.vue'
        )
  },
  {
    path: '/settings/clients/pm',
    name: 'clients-settings-pm',
    meta: {
      middleware: [AuthMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "clients-settings-pm" */ '../views/ClientsSettingsPm.vue'
        )
  },
  {
    path: '/settings/clients/ae',
    name: 'clients-settings-ae',
    meta: {
      middleware: [AuthMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "clients-settings-ae" */ '../views/ClientsSettingsAe.vue'
        )
  },
  // Expenses
  {
    path: '/expenses',
    name: 'expenses',
    meta: {
      middleware: [AuthMiddleware, ManagerAndBiggerMiddleware]
    },
    redirect() {
      return { name: 'expenses-backlink' }
    }
  },
  {
    path: '/expenses/backlink',
    name: 'expenses-backlink',
    meta: {
      middleware: [AuthMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "expenses-backlink" */ '../views/ExpensesBacklink.vue')
  },
  {
    path: '/expenses/content',
    name: 'expenses-content',
    meta: {
      middleware: [AuthMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "expenses-content" */ '../views/ExpensesContent.vue')
  },
  {
    path: '/expenses/ppc',
    name: 'expenses-ppc',
    meta: {
      middleware: [AuthMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "expenses-ppc" */ '../views/ExpensesPPC.vue')
  },
  // Accounting
  {
    path: '/accounting',
    name: 'accounting',
    meta: {
      middleware: [AuthMiddleware, AdminMiddleware, EkaterinaMiddleware, ManagerAndBiggerMiddleware]
    },
    redirect() {
      return { name: 'accounting-dashboard' }
    }
  },
  {
    path: '/accounting/dashboard',
    name: 'accounting-dashboard',
    meta: {
      middleware: [AuthMiddleware, AdminMiddleware, EkaterinaMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "accounting-dashboard" */ '../views/AccountingDashboard.vue'
        )
  },
  {
    path: '/accounting/invoices',
    name: 'accounting-invoices',
    meta: {
      middleware: [AuthMiddleware, AdminMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "invoices" */ '../views/Invoices.vue')
  },
  {
    path: '/accounting/am-commission',
    name: 'accounting-am-commission',
    meta: {
      middleware: [AuthMiddleware, AdminMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "am-commission" */ '../views/AmCommission.vue')
  },
  {
    path: '/accounting/zakhar-invoice-splitter',
    name: 'accounting-zakhar-invoice-splitter',
    meta: {
      middleware: [AuthMiddleware, AdminMiddleware, ManagerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "am-commission" */ '../views/ZakharInvoiceSplitter.vue')
  },
  // Lead Center
  {
    path: '/lead-center',
    name: 'lead-center',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    redirect() {
      return { name: 'lead-center-activities' }
    }
  },
  {
    path: '/lead-center/activities',
    name: 'lead-center-activities',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "lead-center-activities" */ '../views/LeadCenterActivities.vue')
  },
  {
    path: '/lead-center/contacts',
    name: 'lead-center-contacts',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "lead-center-contacts" */ '../views/LeadCenterContacts.vue')
  },
  {
    path: '/lead-center/settings',
    name: 'lead-center-settings',
    meta: {
      activeRootPath: '/lead-center/settings',
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    redirect() {
      return { name: 'lead-center-settings-platform' }
    }
  },
  {
    path: '/lead-center/settings/platform',
    name: 'lead-center-settings-platform',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "lead-center-settings-platform" */ '../views/LeadCenterSettings.vue'
        )
  },
  {
    path: '/lead-center/settings/forms',
    name: 'lead-center-settings-forms',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "lead-center-settings-forms" */ '../views/LeadCenterSettings.vue'
        )
  },
  {
    path: '/lead-center/settings/data-extractor',
    name: 'lead-center-data-extractor',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "lead-center-data-extractor" */ '../views/LeadCenterSettings.vue'
        )
  },
  {
    path: '/lead-center/settings/lead-extractor',
    name: 'lead-center-lead-extractor',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "lead-center-lead-extractor" */ '../views/LeadCenterSettings.vue'
        )
  },
  {
    path: '/lead-center/workflows',
    name: 'lead-center-workflows',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "lead-center-workflows" */ '../views/LeadCenterWorkflows.vue'
        )
  },
  {
    path: '/lead-center/workflows/blocks-library',
    name: 'lead-center-worflows-blocks-library',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "lead-center-workflows" */ '../views/LeadCenterWorkflows.vue'
        )
  },
  {
    path: '/lead-center/workflows/scenarios',
    name: 'lead-center-scenarios',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "lead-center-workflows" */ '../views/LeadCenterWorkflows.vue'
        )
  },
  {
    path: '/lead-center/workflows/:_id',
    name: 'lead-center-workflow',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "lead-center-workflow" */ '../views/LeadCenterWorkflow.vue'
        )
  },
  {
    path: '/lead-center/kpi',
    name: 'lead-center-kpi',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "lead-center-kpi" */ '../views/LeadCenterKpi.vue'
        )
  },
  {
    path: '/lead-center/overview',
    name: 'lead-center-overview',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "lead-center-overview" */ '../views/LeadCenterOverview.vue')
  },
  // Tools
  {
    path: '/tools',
    name: 'tools',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    redirect() {
      return { name: 'tools-gpt' }
    }
  },
  {
    path: '/tools/gpt',
    name: 'tools-gpt',
    meta: {
      middleware: [AuthMiddleware, DoerAndBiggerMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "gpt" */ '../views/Gpt.vue')
  },
  // Other Settings
  {
    path: '/profile',
    name: 'profile',
    meta: {
      middleware: [AuthMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      middleware: [GuestMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
      middleware: [GuestMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue'
      )
  },
  {
    // path: '/reset-password/:token/:email',
    path: '/reset-password/:token',
    name: 'reset-password',
    meta: {
      middleware: [GuestMiddleware]
    },
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue'
      )
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      middleware: [GuestMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/applications-settings',
    name: 'applications-settings',
    meta: {
      middleware: [AuthMiddleware, AdminMiddleware]
    },
    component: () =>
      import(/* webpackChunkName: "applications-settings" */ '../views/ApplicationsSettings.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      middleware: [AuthMiddleware]
    },
    beforeEnter: LogoutHook
  },
  {
    path: '*',
    component: { render: (h) => h('div', ['404! Page Not Found!']) },
    meta: {
      middleware: [AuthMiddleware]
    }
  }
]

export default routes
